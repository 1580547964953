<template>
	<div class="m-login">
		<div class="m-login-form">
			<div class="m-login-box">
				<div class="login-text">
                    <p>登录 <span></span></p>
                </div>
				<el-form ref="form" :rules="rules" :model="form" label-width="0">
					<el-form-item prop="name">
						<el-input v-model="form.name" placeholder="请输入手机号/邮箱">
						</el-input>
					</el-form-item>
					<el-form-item prop="password"> 
						<el-input type="password" v-model="form.password" placeholder="请输入密码">
						</el-input>
					</el-form-item>
                    <el-form-item>
						<el-button style="background: #437EFE;border: 0;width: 100%;height: 45px;font-size:18px;" type="primary" @click="onSubmit">登录</el-button>
					</el-form-item>                    
				</el-form>
                <div class="m-login-bottom">
                    <div class="m-login-huiyuan" @click="goTo('/member')">
                        <img src="../assets/images/hysj.png" alt="">
                        <p>会员升级</p>
                    </div>
                    <div class="m-login-tz">
                        <span @click="goTo('/forget')">忘记密码</span>
                        <span>|</span>
                        <span @click="goTo('/register')">注册</span>
                    </div>
                </div>
			</div>
            <!-- <div class="web-name">
                <img src="../assets/images/web-left.png" alt="">
                <span>河南海外仓供应链管理服务有限公司</span>
                <img src="../assets/images/web-right.png" alt="">
            </div> -->
		</div>
	</div>
</template>

<script>
	import {login} from "../api/index"
	export default {
		data() {
			// var checkPhone = (rule, value, callback) => {
			// 	if (!(/^1[3456789]\d{9}$/.test(value))) {
			// 		callback(new Error('手机号不正确'));
			// 	} else {
			// 		callback()
			// 	}
			// }
			return {
				rules: {                    
					name: [{
							required: true,
							message: '请输入账号',
							trigger: ['blur', 'change']
						},
						// {
						// 	validator: checkPhone,
						// 	trigger: ['blur', 'change']
						// }
					],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: ['blur', 'change']
					}],
				},
				form: {
					name:'',
					password:''
				}
			}
		},
		methods: {

            goTo(url) {
                this.$router.replace({ 
                    path: url ,
                });
            },
			onSubmit() {
				this.$refs.form.validate(async (valid)=>{
					if(valid){
						console.log(this.form)
						let res = await login(this.form)
						console.log(res)
						if(res.code == 200){
							this.$message.success(res.message)
							localStorage.setItem('token',res.data.api_token)
							localStorage.setItem('is_pay',res.data.is_pay)
							this.goTo('/')
						}else{
							this.$message.error(res.message)
						}
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
/deep/ .el-input__inner{
    border: 1px solid #A0A0A0;
    height: 45px;
    // line-height: 50px;
    font-size: 16px;
}
	.m-login {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url(../assets/images/login-bg.png) no-repeat;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.m-login-form {
			// display: flex;
			position: relative;
            margin-right: 18%;            
			.m-login-box {
				display: flex;
				flex-direction: column;
				width: 350px;
				margin: 0px auto;
                background:#fff;
                border-radius: 10px;
                padding:40px 58px;
                .login-text{
                    font-size: 22px;
                    border-bottom: 1px solid #A0A0A0;
                    margin-bottom: 35px;
                    p{
                        color: #6982FE;
                        padding-bottom: 30px;
                        position: relative;
                        text-align: center;
                        span{
                            width: 50%;
                            height: 3px;
                            background: #6982FE;
                            position: absolute;
                            bottom: -1px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                .m-login-bottom{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .m-login-huiyuan{
                        display: flex;
                        align-items: center;
                        color: #999999;
                        font-size: 16px;       
                        cursor: pointer;                 
                        img{width: 20px;margin-right: 5px;}
                    }
                    .m-login-tz{
                        color: #999999;font-size: 16px;cursor: pointer;
                        span:nth-child(2){padding: 0 10px;}
                    }
                }
			}

            .web-name{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;
                color: #fff;
                span{margin: 0 5px;}        
                img{width: 90px;}        
            }
		}
	}
</style>
